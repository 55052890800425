import { RichText } from '@wordpress/block-editor';
import { render, useEffect, useRef } from '@wordpress/element';

import './style.scss';
import Style from './Style';

// Button
document.addEventListener('DOMContentLoaded', () => {
	const allButtons = document.querySelectorAll('.wp-block-btn-button');
	allButtons.forEach(button => {
		const attributes = JSON.parse(button.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<RenderButton attributes={attributes} />
		</>, button);

		button?.removeAttribute('data-attributes');
	});
});

const RenderButton = ({ attributes }) => {
	const { text, url, target, tooltip, isDownload, icon, animationType, animationDuration } = attributes;

	const buttonEl = useRef(null);

	useEffect(() => {
		isDownload ? buttonEl?.current?.setAttribute('download', '') : buttonEl?.current?.removeAttribute('download');

		AOS.init();
	}, []);

	return <a
		className='btnButton'
		data-aos={animationType}
		// data-aos-offset={100}
		// data-aos-delay={50}
		data-aos-duration={animationDuration * 1000}
		// data-aos-easing='ease-in-out'
		// data-aos-mirror='false'
		// data-aos-once='false'
		// data-aos-anchor-placement='bottom-bottom'
		href={url}
		target={target}
		rel='noopener noreferrer'
		tooltip={tooltip}
		position='top'
		ref={buttonEl}
	>
		{icon?.class && <i className={icon?.class}></i>}

		<RichText.Content className='btnText' tagName='span' value={text} />
	</a>
}